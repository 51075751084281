var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main user-layout-register"},[_c('h3',[_c('span',[_vm._v(_vm._s(_vm.$t('user.register.register')))])]),_c('a-form',{ref:"formRegister",attrs:{"id":"formRegister","form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
            rules: [
              {
                required: true,
                type: 'email',
                message: _vm.$t('user.email.required')
              }
            ],
            validateTrigger: ['change', 'blur']
          }
        ]),expression:"[\n          'email',\n          {\n            rules: [\n              {\n                required: true,\n                type: 'email',\n                message: $t('user.email.required')\n              }\n            ],\n            validateTrigger: ['change', 'blur']\n          }\n        ]"}],attrs:{"size":"large","type":"text","placeholder":_vm.$t('user.register.email.placeholder')}})],1),_c('a-popover',{attrs:{"placement":"rightTop","trigger":['focus'],"get-popup-container":function (trigger) { return trigger.parentElement; }},model:{value:(_vm.state.passwordLevelChecked),callback:function ($$v) {_vm.$set(_vm.state, "passwordLevelChecked", $$v)},expression:"state.passwordLevelChecked"}},[_c('template',{slot:"content"},[_c('div',{style:({ width: '240px' })},[_c('div',{class:['user-register', _vm.passwordLevelClass]},[_vm._v(" "+_vm._s(_vm.$t(_vm.passwordLevelName))+" ")]),_c('a-progress',{attrs:{"percent":_vm.state.percent,"show-info":false,"stroke-color":_vm.passwordLevelColor}}),_c('div',{staticStyle:{"margin-top":"10px"}},[_c('span',[_vm._v(_vm._s(_vm.$t('user.register.password.popover-message'))+" ")])])],1)]),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'password',
            {
              rules: [
                { required: true, message: _vm.$t('user.password.required') },
                { validator: _vm.handlePasswordLevel }
              ],
              validateTrigger: ['change', 'blur']
            }
          ]),expression:"[\n            'password',\n            {\n              rules: [\n                { required: true, message: $t('user.password.required') },\n                { validator: handlePasswordLevel }\n              ],\n              validateTrigger: ['change', 'blur']\n            }\n          ]"}],attrs:{"size":"large","placeholder":_vm.$t('user.register.password.placeholder')},on:{"click":_vm.handlePasswordInputClick}})],1)],2),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'password2',
          {
            rules: [
              { required: true, message: _vm.$t('user.password.required') },
              { validator: this.handlePasswordCheck }
            ],
            validateTrigger: ['change', 'blur']
          }
        ]),expression:"[\n          'password2',\n          {\n            rules: [\n              { required: true, message: $t('user.password.required') },\n              { validator: this.handlePasswordCheck }\n            ],\n            validateTrigger: ['change', 'blur']\n          }\n        ]"}],attrs:{"size":"large","placeholder":_vm.$t('user.register.confirm-password.placeholder')}})],1),_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'mobile',
          {
            rules: [
              {
                required: true,
                message: _vm.$t('user.phone-number.required'),
                pattern: /^1[3456789]\d{9}$/
              },
              { validator: this.handlePhoneCheck }
            ],
            validateTrigger: ['change', 'blur']
          }
        ]),expression:"[\n          'mobile',\n          {\n            rules: [\n              {\n                required: true,\n                message: $t('user.phone-number.required'),\n                pattern: /^1[3456789]\\d{9}$/\n              },\n              { validator: this.handlePhoneCheck }\n            ],\n            validateTrigger: ['change', 'blur']\n          }\n        ]"}],attrs:{"size":"large","placeholder":_vm.$t('user.login.mobile.placeholder')}},[_c('a-select',{attrs:{"slot":"addonBefore","size":"large","default-value":"+86"},slot:"addonBefore"},[_c('a-select-option',{attrs:{"value":"+86"}},[_vm._v(" +86 ")]),_c('a-select-option',{attrs:{"value":"+87"}},[_vm._v(" +87 ")])],1)],1)],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{staticClass:"gutter-row",attrs:{"span":16}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'captcha',
              {
                rules: [{ required: true, message: '请输入验证码' }],
                validateTrigger: 'blur'
              }
            ]),expression:"[\n              'captcha',\n              {\n                rules: [{ required: true, message: '请输入验证码' }],\n                validateTrigger: 'blur'\n              }\n            ]"}],attrs:{"size":"large","type":"text","placeholder":_vm.$t('user.login.mobile.verification-code.placeholder')}},[_c('a-icon',{style:({ color: 'rgba(0,0,0,.25)' }),attrs:{"slot":"prefix","type":"mail"},slot:"prefix"})],1)],1)],1),_c('a-col',{staticClass:"gutter-row",attrs:{"span":8}},[_c('a-button',{staticClass:"getCaptcha",attrs:{"size":"large","disabled":_vm.state.smsSendBtn},domProps:{"textContent":_vm._s(
            (!_vm.state.smsSendBtn &&
              _vm.$t('user.register.get-verification-code')) ||
              _vm.state.time + ' s'
          )},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.getCaptcha.apply(null, arguments)}}})],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"register-button",attrs:{"size":"large","type":"primary","html-type":"submit","loading":_vm.registerBtn,"disabled":_vm.registerBtn},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('user.register.register'))+" ")]),_c('router-link',{staticClass:"login",attrs:{"to":{ name: 'login' }}},[_vm._v(" "+_vm._s(_vm.$t('user.register.sign-in'))+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }