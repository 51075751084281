<template>
  <div class="main user-layout-register">
    <h3>
      <span>{{ $t('user.register.register') }}</span>
    </h3>
    <a-form id="formRegister" ref="formRegister" :form="form">
      <a-form-item>
        <a-input
          v-decorator="[
            'email',
            {
              rules: [
                {
                  required: true,
                  type: 'email',
                  message: $t('user.email.required')
                }
              ],
              validateTrigger: ['change', 'blur']
            }
          ]"
          size="large"
          type="text"
          :placeholder="$t('user.register.email.placeholder')"
        />
      </a-form-item>

      <a-popover
        v-model="state.passwordLevelChecked"
        placement="rightTop"
        :trigger="['focus']"
        :get-popup-container="trigger => trigger.parentElement"
      >
        <template slot="content">
          <div :style="{ width: '240px' }">
            <div :class="['user-register', passwordLevelClass]">
              {{ $t(passwordLevelName) }}
            </div>
            <a-progress
              :percent="state.percent"
              :show-info="false"
              :stroke-color="passwordLevelColor"
            />
            <div style="margin-top: 10px;">
              <span>{{ $t('user.register.password.popover-message') }} </span>
            </div>
          </div>
        </template>
        <a-form-item>
          <a-input-password
            v-decorator="[
              'password',
              {
                rules: [
                  { required: true, message: $t('user.password.required') },
                  { validator: handlePasswordLevel }
                ],
                validateTrigger: ['change', 'blur']
              }
            ]"
            size="large"
            :placeholder="$t('user.register.password.placeholder')"
            @click="handlePasswordInputClick"
          />
        </a-form-item>
      </a-popover>

      <a-form-item>
        <a-input-password
          v-decorator="[
            'password2',
            {
              rules: [
                { required: true, message: $t('user.password.required') },
                { validator: this.handlePasswordCheck }
              ],
              validateTrigger: ['change', 'blur']
            }
          ]"
          size="large"
          :placeholder="$t('user.register.confirm-password.placeholder')"
        />
      </a-form-item>

      <a-form-item>
        <a-input
          v-decorator="[
            'mobile',
            {
              rules: [
                {
                  required: true,
                  message: $t('user.phone-number.required'),
                  pattern: /^1[3456789]\d{9}$/
                },
                { validator: this.handlePhoneCheck }
              ],
              validateTrigger: ['change', 'blur']
            }
          ]"
          size="large"
          :placeholder="$t('user.login.mobile.placeholder')"
        >
          <a-select slot="addonBefore" size="large" default-value="+86">
            <a-select-option value="+86">
              +86
            </a-select-option>
            <a-select-option value="+87">
              +87
            </a-select-option>
          </a-select>
        </a-input>
      </a-form-item>
      <!--<a-input-group size="large" compact>
            <a-select style="width: 20%" size="large" defaultValue="+86">
              <a-select-option value="+86">+86</a-select-option>
              <a-select-option value="+87">+87</a-select-option>
            </a-select>
            <a-input style="width: 80%" size="large" placeholder="11 位手机号"></a-input>
          </a-input-group>-->

      <a-row :gutter="16">
        <a-col class="gutter-row" :span="16">
          <a-form-item>
            <a-input
              v-decorator="[
                'captcha',
                {
                  rules: [{ required: true, message: '请输入验证码' }],
                  validateTrigger: 'blur'
                }
              ]"
              size="large"
              type="text"
              :placeholder="
                $t('user.login.mobile.verification-code.placeholder')
              "
            >
              <a-icon
                slot="prefix"
                type="mail"
                :style="{ color: 'rgba(0,0,0,.25)' }"
              />
            </a-input>
          </a-form-item>
        </a-col>
        <a-col class="gutter-row" :span="8">
          <a-button
            class="getCaptcha"
            size="large"
            :disabled="state.smsSendBtn"
            @click.stop.prevent="getCaptcha"
            v-text="
              (!state.smsSendBtn &&
                $t('user.register.get-verification-code')) ||
                state.time + ' s'
            "
          />
        </a-col>
      </a-row>

      <a-form-item>
        <a-button
          size="large"
          type="primary"
          html-type="submit"
          class="register-button"
          :loading="registerBtn"
          :disabled="registerBtn"
          @click.stop.prevent="handleSubmit"
        >
          {{ $t('user.register.register') }}
        </a-button>
        <router-link class="login" :to="{ name: 'login' }">
          {{ $t('user.register.sign-in') }}
        </router-link>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { getSmsCaptcha } from '@/api/login'
import { deviceMixin } from '@/store/device-mixin'
import { scorePassword } from '@/utils/util'

const levelNames = {
  0: 'user.password.strength.short',
  1: 'user.password.strength.low',
  2: 'user.password.strength.medium',
  3: 'user.password.strength.strong'
}
const levelClass = {
  0: 'error',
  1: 'error',
  2: 'warning',
  3: 'success'
}
const levelColor = {
  0: '#ff0000',
  1: '#ff0000',
  2: '#ff7e05',
  3: '#52c41a'
}
export default {
  name: 'register',
  components: {},
  mixins: [deviceMixin],
  data() {
    return {
      form: this.$form.createForm(this),

      state: {
        time: 60,
        level: 0,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: '#FF0000'
      },
      registerBtn: false
    }
  },
  computed: {
    passwordLevelClass() {
      return levelClass[this.state.passwordLevel]
    },
    passwordLevelName() {
      return levelNames[this.state.passwordLevel]
    },
    passwordLevelColor() {
      return levelColor[this.state.passwordLevel]
    }
  },
  watch: {
    'state.passwordLevel'(val) {
      console.log(val)
    }
  },
  methods: {
    handlePasswordLevel(rule, value, callback) {
      if (value === '') {
        return callback()
      }
      console.log('scorePassword ; ', scorePassword(value))
      if (value.length >= 6) {
        if (scorePassword(value) >= 30) {
          this.state.level = 1
        }
        if (scorePassword(value) >= 60) {
          this.state.level = 2
        }
        if (scorePassword(value) >= 80) {
          this.state.level = 3
        }
      } else {
        this.state.level = 0
        callback(new Error(this.$t('user.password.strength.msg')))
      }
      this.state.passwordLevel = this.state.level
      this.state.percent = this.state.level * 33
    },

    handlePasswordCheck(rule, value, callback) {
      const password = this.form.getFieldValue('password')
      // console.log('value', value)
      if (value === undefined) {
        callback(new Error(this.$t('user.password.required')))
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error(this.$t('user.password.twice.msg')))
      }
      callback()
    },

    handlePhoneCheck(rule, value, callback) {
      console.log('handlePhoneCheck, rule:', rule)
      console.log('handlePhoneCheck, value', value)
      console.log('handlePhoneCheck, callback', callback)

      callback()
    },

    handlePasswordInputClick() {
      if (!this.isMobile) {
        this.state.passwordLevelChecked = true
        return
      }
      this.state.passwordLevelChecked = false
    },

    handleSubmit() {
      const {
        form: { validateFields },
        state,
        $router
      } = this
      validateFields({ force: true }, (err, values) => {
        if (!err) {
          state.passwordLevelChecked = false
          $router.push({ name: 'registerResult', params: { ...values } })
        }
      })
    },

    getCaptcha(e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        $message,
        $notification
      } = this

      validateFields(['mobile'], { force: true }, (err, values) => {
        if (!err) {
          state.smsSendBtn = true

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(interval)
            }
          }, 1000)

          const hide = $message.loading('验证码发送中..', 0)

          getSmsCaptcha({ mobile: values.mobile })
            .then(res => {
              setTimeout(hide, 2500)
              $notification['success']({
                message: '提示',
                description:
                  '验证码获取成功，您的验证码为：' + res.result.captcha,
                duration: 8
              })
            })
            .catch(err => {
              setTimeout(hide, 1)
              clearInterval(interval)
              state.time = 60
              state.smsSendBtn = false
              this.requestFailed(err)
            })
        }
      })
    },
    requestFailed(err) {
      this.$notification['error']({
        message: '错误',
        description:
          ((err.response || {}).data || {}).message ||
          '请求出现错误，请稍后再试',
        duration: 4
      })
      this.registerBtn = false
    }
  }
}
</script>
<style lang="less">
.user-register {
  &.error {
    color: #ff0000;
  }

  &.warning {
    color: #ff7e05;
  }

  &.success {
    color: #52c41a;
  }
}

.user-layout-register {
  .ant-input-group-addon:first-child {
    background-color: #fff;
  }
}
</style>
<style lang="less" scoped>
.user-layout-register {
  & > h3 {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .getCaptcha {
    display: block;
    width: 100%;
    height: 40px;
  }

  .register-button {
    width: 50%;
  }

  .login {
    float: right;
    line-height: 40px;
  }
}
</style>
